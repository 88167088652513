import React from "react"
import { graphql } from "gatsby"

import SEO from "../../components/shell/seo"
import CodeEditor from "../../components/common/CodeEditor"
import Layout from "../../components/shell/layout"

const CodeExampleIDPage = ({ params, data }) => {
  const result = data.allCodeExampleJson.nodes.find(
    x => x.Id === params.codeExampleId
  )

  return (
    <Layout>
      <SEO title="IT CodeExample" />
      <CodeEditor
        data={result && result.codeSnippet ? result.codeSnippet.code : ""}
      />
    </Layout>
  )
}

export default CodeExampleIDPage

export const query = graphql`
  {
    allCodeExampleJson {
      nodes {
        Id
        codeSnippet {
          code
        }
      }
    }
  }
`
