import React, { useEffect } from "react";
import beautify from "js-beautify";

import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import RestoreIcon from "@material-ui/icons/Restore";
import ClearIcon from "@material-ui/icons/Clear";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import "./CodeEditor.css";

let CodeMirror = null;
let Clipboard = null;

if (
  typeof window !== "undefined" &&
  typeof window.navigator !== "undefined" &&
  typeof document !== "undefined"
) {
  require("codemirror/lib/codemirror.css");
  require("codemirror/theme/monokai.css");
  require("codemirror/addon/display/placeholder");
  require("codemirror/addon/display/rulers");
  require("codemirror/addon/edit/matchbrackets");
  require("codemirror/addon/edit/matchtags");

  require("codemirror/mode/css/css");
  require("codemirror/mode/htmlmixed/htmlmixed");
  require("codemirror/mode/javascript/javascript");
  require("codemirror/mode/php/php");
  Clipboard = require("clipboard");
  CodeMirror = require("codemirror/lib/codemirror.js");
}

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "20px 20px 100px 20px",
    paddingBottom: "507px",
    transition: "all .3s ease-in-out",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "260px",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "263px",
    },
    "@media screen and (max-width: 356px)": {
      paddingBottom: "526px",
    },
  },
}));

export default function CodeEditor(props) {
  const htmlTemplate =
    "<!DOCTYPE html>\n<html>\n    <head>\n        <style>\n            {/* Write your styles here */}\n        </style>\n    </head>\n    <body>\n        {/* Write your HTML here */}\n        <script>\n            {/* Write your javascript here */}\n        </script>\n    </body>\n</html>";
  var codeMirrorInstance;
  var resultUI;
  var defaultSettings = {
    aboutDialogVisible: false,
    advancedSettingsTab: "html",
    advancedSettingsTabPosition: "left",
    advancedSettingsVisible: false,
    cleanTooltipVisible: false,
    copyTooltipVisible: false,
    editor: null,
    isCopySupported: Clipboard ? Clipboard.isSupported() : false,
    isDragging: false,
    isMac:
      typeof navigator !== "undefined"
        ? /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
        : false,
    isIos:
      typeof navigator !== "undefined"
        ? /(iPhone|iPod|iPad)/i.test(navigator.platform)
        : false,

    // Stats
    charCount: 0,
    size: 0,
    lines: 0,

    // Base settings
    copyOnClean: true,
    detectIndent: true,
    isDark: true,
    fontSize: 13,
    indentSpaces: 2,
    indentWithTabs: false,
    lineNumbers: true,
    matchBrackets: true,
    mode: "php",
    ruler: 120,
    wrapLines: true,

    // HTML settings
    htmlIndentScripts: "normal",
    htmlWrapAttributes: "auto",
    htmlPreserveNewlines: false,
    htmlIndentInnerHtml: false,
    htmlIndentEmptyLines: false,
    htmlEndWithNewline: false,

    // CSS settings
    cssSelectorSeparatorNewline: false,
    cssNewlineBetweenRules: false,
    cssIndentEmptyLines: false,
    cssEndWithNewline: false,

    // JS settings
    jsBraceStyle: "collapse-preserve-inline",
    jsOperatorPosition: "before-newline",
    jsPreserveNewlines: false,
    jsSpaceInParen: false,
    jsSpaceInEmptyParen: false,
    jsSpaceAfterAnonFunction: false,
    jsSpaceAfterNamedFunction: false,
    jsUnindentChainedMethods: false,
    jsBreakChainedMethods: false,
    jsKeepArrayIndentation: false,
    jsUnescapeStrings: false,
    jsCommaFirst: false,
    jsIndentEmptyLines: false,
    jsEndWithNewline: false,

    // Only settings in this array will be stored
    settingsToStore: [
      // Base settings
      "copyOnClean",
      "detectIndent",
      "isDark",
      "fontSize",
      "indentSpaces",
      "indentWithTabs",
      "lineNumbers",
      "matchBrackets",
      "mode",
      "ruler",
      "wrapLines",

      // HTML
      "htmlIndentScripts",
      "htmlWrapAttributes",
      "htmlPreserveNewlines",
      "htmlIndentInnerHtml",
      "htmlIndentEmptyLines",
      "htmlEndWithNewline",

      // CSS
      "cssSelectorSeparatorNewline",
      "cssNewlineBetweenRules",
      "cssIndentEmptyLines",
      "cssEndWithNewline",

      // JS
      "jsBraceStyle",
      "jsOperatorPosition",
      "jsPreserveNewlines",
      "jsSpaceInParen",
      "jsSpaceInEmptyParen",
      "jsSpaceAfterAnonFunction",
      "jsSpaceAfterNamedFunction",
      "jsUnindentChainedMethods",
      "jsBreakChainedMethods",
      "jsKeepArrayIndentation",
      "jsUnescapeStrings",
      "jsCommaFirst",
      "jsIndentEmptyLines",
      "jsEndWithNewline",
    ],
  };

  function handleKeyDown(event) {
    const meta = event.ctrlKey || event.metaKey;
    const shift = event.shiftKey;
    const key = event.key;

    // Format code
    if (meta && key === "s") {
      event.preventDefault();
      clean();
    }

    // Clear code
    if (meta && key === "n") {
      event.preventDefault();
      clear();
    }

    // HTML
    if (meta && shift && key === "h") {
      event.preventDefault();
      defaultSettings.mode = "html";
    }

    // CSS
    if (meta && shift && key === "c") {
      event.preventDefault();
      defaultSettings.mode = "css";
    }

    // JavaScript
    if (meta && shift && key === "j") {
      event.preventDefault();
      defaultSettings.mode = "js";
    }

    // Clean
    if (meta && shift && key === "Enter") {
      event.preventDefault();
      clean();
    }

    // Clear
    if (meta && shift && key === "Backspace") {
      event.preventDefault();
      clear();
    }
  }

  function clean() {
    if (!codeMirrorInstance) {
      return;
    }

    const code = codeMirrorInstance.getValue();
    const currentSettings = {
      indent_char: " ",
      indent_size: defaultSettings.indentSpaces,
      indent_with_tabs: defaultSettings.indentWithTabs,
      end_with_newline: defaultSettings,
      preserve_newlines: true,
      max_preserve_newlines: 1,
      wrap_line_length: defaultSettings.ruler,
      css: {
        selector_separator_newline: defaultSettings.cssSelectorSeparatorNewline,
        newline_between_rules: defaultSettings.cssNewlineBetweenRules,
        indent_empty_lines: defaultSettings.cssIndentEmptyLines,
        end_with_newline: defaultSettings.cssEndWithNewline,
      },
      html: {
        end_with_newline: defaultSettings.htmlEndWithNewline,
        extra_liners: [],
        indent_empty_lines: defaultSettings.htmlIndentEmptyLines,
        indent_inner_html: defaultSettings.htmlIndentInnerHtml,
        indent_scripts: defaultSettings.htmlIndentScripts,
        preserve_newlines: defaultSettings.htmlPreserveNewlines,
        wrap_attributes: defaultSettings.htmlWrapAttributes,
      },
      js: {
        brace_style: defaultSettings.jsBraceStyle,
        operator_position: defaultSettings.jsOperatorPosition,
        preserve_newlines: defaultSettings.jsPreserveNewlines,
        space_in_paren: defaultSettings.jsSpaceInParen,
        space_in_empty_paren: defaultSettings.jsSpaceInEmptyParen,
        space_after_anon_function: defaultSettings.jsSpaceAfterAnonFunction,
        space_after_named_function: defaultSettings.jsSpaceAfterNamedFunction,
        unindent_chained_methods: defaultSettings.jsUnindentChainedMethods,
        break_chained_methods: defaultSettings.jsBreakChainedMethods,
        keep_array_indentation: defaultSettings.jsKeepArrayIndentation,
        unescape_strings: defaultSettings.jsUnescapeStrings,
        comma_first: defaultSettings.jsCommaFirst,
        indent_empty_lines: defaultSettings.jsIndentEmptyLines,
        end_with_newline: defaultSettings.jsEndWithNewline,
      },
    };

    // Do nothing if there's no code
    if (!code.length) {
      codeMirrorInstance.setValue("");
      return;
    }

    // Beautify it!
    switch (defaultSettings.mode) {
      case "php":
        codeMirrorInstance.setValue(beautify.html(code, currentSettings));
        break;
      case "css":
        codeMirrorInstance.setValue(beautify.css(code, currentSettings));
        break;
      case "js":
        codeMirrorInstance.setValue(beautify.js(code, currentSettings));
        break;
    }
  }

  function updateResultUI() {
    resultUI.srcdoc = codeMirrorInstance ? codeMirrorInstance.getValue() : "";
  }

  // Clears the editor and its undo history
  function clear() {
    codeMirrorInstance.setValue("");
    codeMirrorInstance.clearHistory();
  }

  function initCodeEditor() {
    if (typeof document === "undefined") return;

    codeMirrorInstance = CodeMirror.fromTextArea(
      document.getElementById("editor"),
      {
        lineNumbers: defaultSettings.lineNumbers,
        lineWrapping: defaultSettings.wrapLines,
        mode: defaultSettings.mode,
        matchBrackets: defaultSettings.matchBrackets,
        matchTags: defaultSettings.matchBrackets ? { bothTags: true } : false,
        placeholder: `1. Press Ctr or command + s to format the code while typing
   or you can use buttons from the toolbar
2. Enter your code here and enjoy your coding.....
            `,
        rulers: [{ column: defaultSettings.ruler }],
        extraKeys: {
          Tab: (cm) => cm.execCommand("indentMore"),
          "Shift-Tab": (cm) => cm.execCommand("indentLess"),
        },
      }
    );
    updateData(props.data);
    codeMirrorInstance.save();
    codeMirrorInstance.focus();

    codeMirrorInstance.on("change", (e) => updateResultUI(e));
    document.addEventListener("keydown", handleKeyDown);
  }

  function getTemplate() {
    updateData(htmlTemplate);
  }

  function updateData(data) {
    codeMirrorInstance.setValue(data || "");
    clean();
    updateResultUI();
  }

  useEffect(() => {
    if (typeof document !== "undefined") {
      resultUI = document.getElementById("result-ui");
    }

    initCodeEditor();

    return () => {
      codeMirrorInstance.toTextArea();
    };
  }, []);

  const classes = useStyles();
  return (
    <Container className={classes.main}>
      <div className="toolbar__wrapper">
        <a className="toolbar__button" title="Default">
          <RestoreIcon
            onClick={() => {
              updateData(props.data);
            }}
          />
        </a>
        <a className="toolbar__button" title="Default">
          <FormatAlignCenterIcon onClick={clean} />
        </a>
        <a className="toolbar__button" title="Delete">
          <ClearIcon onClick={clear} />
        </a>
        <a className="toolbar__button" title="Challenge">
          <ImportExportIcon onClick={getTemplate} />
        </a>
        {/* <a className="toolbar__button" href="#" title="Beautify"><PlayArrowIcon onClick={updateResultUI} /></a> */}
      </div>
      <div className="code-editor">
        <textarea
          id="editor"
          className="code-editor__editor"
          autoComplete="off"
          onChange={(e) => updateResultUI(e)}
        />
        <iframe className="code-editor__result" id="result-ui"></iframe>
      </div>
    </Container>
  );
}
